<template>
  <div>
    <h1 :class="$style.pageTitle">所属車両管理</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading != 3" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <form-belong-vehicles
              v-if="loading == 3"
              :propAllVehicles="allVehicles"
              :propActiveVehicles="belongVehicles"
              :propAllBranches="allBranches"
              :backPath="backPath"
              :putPath="putPath"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import FormBelongVehicles from '../../../components/FormComponents/BelongVehicles'

export default {
  components: {
    FormBelongVehicles,
  },
  data() {
    return {
      loading: 0,
      allVehicles: [],
      belongVehicles: [],
      allBranches: [],
      backPath: '/company',
      putPath: 'groups/belong_vehicles/' + this.$route.params.id,
    }
  },
  created() {
    const resultGroupVehicles = Vue.prototype.$api.send('get', 'groups/' + this.$route.params.id)
    resultGroupVehicles.then(response => {
      this.loading++
      this.belongVehicles = response.vehicles.map((item) => { return item.id })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 所属車両の取得に失敗しました。',
        })
        this.loading++
      })

    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(myResponse => {
      const concatVehicleNameAndNo = myResponse.concat_vehicle_name_and_no
      const resultAllVehicles = Vue.prototype.$api.send('get', 'user/vehicles')
      resultAllVehicles.then(response => {
        this.loading++
        this.allVehicles = response.map((item) => {
          if (concatVehicleNameAndNo && item.number) item.name = item.name + ',' + item.number
          return { label: item.name, value: item.id, checked: true, branch: item.branch_id }
        })
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 車両の取得に失敗しました。',
          })
          this.loading++
        })
    })
    const resultAllBranches = Vue.prototype.$api.send('get', 'branches')
    resultAllBranches.then(response => {
      this.loading++
      this.allBranches = response.map((item) => { return { label: item.name, value: item.id } })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
        this.loading++
      })
  },
}

</script>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
