var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { class: _vm.$style.pageTitle }, [_vm._v("所属車両管理")]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _vm.loading != 3
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              _vm.loading == 3
                ? _c("form-belong-vehicles", {
                    attrs: {
                      propAllVehicles: _vm.allVehicles,
                      propActiveVehicles: _vm.belongVehicles,
                      propAllBranches: _vm.allBranches,
                      backPath: _vm.backPath,
                      putPath: _vm.putPath
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }